import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import * as Auth from './auth.actions';
import { AuthService } from './auth.service';

@Injectable()
export class AuthEffects {
    @Effect()
    public login$ = this.actions$
        .ofType(Auth.LOGIN)
        .pipe(
            map((action: Auth.Login) => action.payload),
            exhaustMap(auth =>
                this.authService
                    .login(auth)
                    .pipe(
                        map(user => new Auth.LoginSuccess({ user })),
                        catchError(error =>
                            of(
                                new Auth.LoginFailure(
                                    error.status === 401
                                        ? 'Unknown username or password'
                                        : error.error
                                            ? error.message
                                            : error
                                )
                            )
                        )
                    )
            )
        );

    @Effect({ dispatch: false })
    public logout$ = this.actions$
        .ofType(Auth.LOGOUT)
        .pipe(switchMap(() => this.authService.logout()));

    @Effect({ dispatch: false })
    public loginRedirect$ = this.actions$
        .ofType(Auth.LOGIN_REDIRECT, Auth.LOGOUT)
        .pipe(
            tap(authed => {
                this.router.navigate(['/']);
            })
        );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router
    ) {}
}
