import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule
} from '@angular/material';
import { RouterModule } from '@angular/router';

import { AppComponent } from './layout/app/app.component';
import { ContainerComponent } from './layout/container/container.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';

import { AuthModule } from '../auth/auth.module';

export const COMPONENTS = [
    AppComponent,
    ContainerComponent,
    SidenavComponent,
    NotFoundPageComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AuthModule,

        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,

        FlexLayoutModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class CoreModule {
    public static forRoot() {
        return {
            ngModule: CoreModule,
            providers: []
        };
    }
}
