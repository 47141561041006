import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import * as Auth from './auth.actions';
import * as fromAuth from './auth.reducer';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private store: Store<fromAuth.ILoginPageState>) {}

    public canActivate(): Observable<boolean> {
        return this.store.select(fromAuth.getLoggedIn).pipe(
            map(authed => {
                if (!authed && environment.production) {
                    this.store.dispatch(new Auth.LoginRedirect());
                    return false;
                }

                return true;
            }),
            take(1)
        );
    }
}
