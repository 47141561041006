import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthContainerComponent } from './auth-container/auth-container.component';
import { AuthFormComponent } from './auth-form/auth-form.component';

import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule
} from '@angular/material';
import { AuthEffects } from './auth.effects';
import { AuthGuard } from './auth.guard';
import { reducer } from './auth.reducer';
import { AuthService } from './auth.service';

export const COMPONENTS = [AuthContainerComponent, AuthFormComponent];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,

        MatCardModule,
        MatProgressBarModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,

        StoreModule.forFeature('auth', reducer),
        EffectsModule.forFeature([AuthEffects])
    ],

    declarations: COMPONENTS,
    exports: COMPONENTS,
    providers: [AuthService, AuthGuard]
})
export class AuthModule {}
