import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, mapTo } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IAuthenticate } from '../user/user.model';

@Injectable()
export class AuthService {
    constructor(private http: HttpClient) {}

    public login({ username, password }: IAuthenticate) {
        const data = {
            username,
            password
        };

        return this.http.post(`${environment.api_endpoint}/login`, data).pipe(
            map((user: any) => {
                return {
                    name: user.name
                };
            })
        );
    }

    public logout() {
        return this.http
            .get(`${environment.api_endpoint}/logout`)
            .pipe(mapTo(true));
    }
}
