import { Action } from '@ngrx/store';
import { TradeOrder } from 'shared/order';

export const NEW_ORDER = '[Order] New Order';
export const NEW_COMBINED_ORDER = '[Order] New Combined Order';

export const CREATE_ORDER = '[Order] Create Order';
export const CREATE_ORDER_SUCCESS = '[Order] Create Order Success';
export const CREATE_ORDER_FAIL = '[Order] Create Order Fail';

export const LOAD_ORDER = '[Order] Load Order';
export const LOAD_ORDER_SUCCESS = '[Order] Load Order Success';
export const LOAD_ORDER_FAIL = '[Order] Load Order Fail';

export const SHOW_ORDERS = '[Orders] Show Orders';

export const LOAD_ORDERS = '[Orders] Load Order';
export const LOAD_ORDERS_SUCCESS = '[Orders] Load Order Success';
export const LOAD_ORDERS_FAIL = '[Orders] Load Order Fail';

export const SHOW_ORDER = '[Order] Show Order';
export const CHANGE_ORDER_ACTION = '[Order] Change Order Action';

export const SAVE_ORDER = '[Order] Save Order';
export const SAVE_ORDER_SUCCESS = '[Order] Save Order Success';
export const SAVE_ORDER_FAIL = '[Order] Save Order Fail';

export const SUBMIT_ORDER = '[Order] Submit Order';
export const SUBMIT_ORDER_SUCCESS = '[Order] Submit Order Success';
export const SUBMIT_ORDER_FAIL = '[Order] Submit Order Fail';

export class NewOrder implements Action {
    public readonly type = NEW_ORDER;
}

export class NewCombinedOrder implements Action {
    public readonly type = NEW_COMBINED_ORDER;
}

export class CreateOrder implements Action {
    public readonly type = CREATE_ORDER;

    constructor(public payload?: { isCombined: boolean }) {}
}

export class CreateOrderSuccess implements Action {
    public readonly type = CREATE_ORDER_SUCCESS;

    constructor(public payload: Partial<TradeOrder>) {}
}

export class CreateOrderFail implements Action {
    public readonly type = CREATE_ORDER_FAIL;
}

export class ShowOrder implements Action {
    public readonly type = SHOW_ORDER;

    constructor(public payload: string) {}
}

export class LoadOrder implements Action {
    public readonly type = LOAD_ORDER;

    constructor(public payload: string) {}
}

export class LoadOrderSuccess implements Action {
    public readonly type = LOAD_ORDER_SUCCESS;

    constructor(public payload: Partial<TradeOrder>) {}
}

export class LoadOrderFail implements Action {
    public readonly type = LOAD_ORDER_FAIL;
}

export class ShowOrders implements Action {
    public readonly type = SHOW_ORDERS;

    constructor(
        public payload: {
            startDate?: Date;
            endDate?: Date;
        }
    ) {}
}

export class LoadOrders implements Action {
    public readonly type = LOAD_ORDERS;

    constructor(
        public payload: {
            startDate?: Date;
            endDate?: Date;
        }
    ) {}
}

export class LoadOrdersSuccess implements Action {
    public readonly type = LOAD_ORDERS_SUCCESS;

    constructor(public payload: Partial<TradeOrder>[]) {}
}

export class LoadOrdersFail implements Action {
    public readonly type = LOAD_ORDERS_FAIL;
}

export class SaveOrder implements Action {
    public readonly type = SAVE_ORDER;

    constructor(public payload: Partial<TradeOrder>) {}
}

export class SaveOrderSuccess implements Action {
    public readonly type = SAVE_ORDER_SUCCESS;

    constructor(public payload: Partial<TradeOrder>) {}
}

export class SaveOrderFail implements Action {
    public readonly type = SAVE_ORDER_FAIL;

    constructor(public payload: string) {}
}

export class SubmitOrder implements Action {
    public readonly type = SUBMIT_ORDER;

    constructor(public payload: Partial<TradeOrder>) {}
}

export class SubmitOrderSuccess implements Action {
    public readonly type = SUBMIT_ORDER_SUCCESS;

    constructor(public payload: Partial<TradeOrder>) {}
}

export class SubmitOrderFail implements Action {
    public readonly type = SUBMIT_ORDER_FAIL;

    constructor(public payload: string) {}
}

export type Actions =
    | NewOrder
    | NewCombinedOrder
    | CreateOrder
    | CreateOrderSuccess
    | CreateOrderFail
    | LoadOrder
    | LoadOrderSuccess
    | LoadOrderFail
    | SaveOrder
    | SaveOrderSuccess
    | SaveOrderFail
    | SubmitOrder
    | SubmitOrderSuccess
    | SubmitOrderFail
    | ShowOrder
    | LoadOrders
    | LoadOrdersSuccess
    | LoadOrdersFail
    | ShowOrders;
