import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material';
import { Store } from '@ngrx/store';
import { noop, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as auth from '../../../auth/auth.actions';
import * as fromAuth from '../../../auth/auth.reducer';
import * as order from '../../../orders/order.actions';
import * as fromCore from '../../core.reducer';

@Component({
    selector: 'app-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    @ViewChild('nav') public sideNav: MatSidenav;

    public loggedIn$: Observable<boolean>;
    public user$: Observable<any>;

    public sideNavMode: string = 'side';
    public sideNavOpen: boolean = true;
    public mediaMode: any;

    constructor(
        private store: Store<fromCore.IState>,
        private media: ObservableMedia
    ) {
        this.loggedIn$ = this.store.select(fromAuth.getLoggedIn);
        this.user$ = this.store
            .select(fromAuth.getUser)
            .pipe(map(user => (user ? user.name : '')));

        this.media
            .asObservable()
            .pipe(
                tap((change: MediaChange) => {
                    this.mediaMode = change;

                    if (change.mqAlias === 'xs') {
                        this.sideNavOpen = false;
                        this.sideNavMode = 'over';
                    } else {
                        this.sideNavMode = 'side';
                        this.sideNavOpen = true;
                    }

                    if (this.sideNav) {
                        this.sideNav.toggle(this.sideNavOpen);
                    }
                })
            )
            .subscribe(noop);
    }

    public toggleSideNav() {
        this.sideNav.toggle();
    }

    public newOrder() {
        this.store.dispatch(new order.NewOrder());
    }

    public newCombinedOrder() {
        this.store.dispatch(new order.NewCombinedOrder());
    }

    public logout() {
        this.store.dispatch(new auth.Logout());
    }
}
