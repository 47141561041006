import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { environment } from '../../environments/environment';
import { IUser } from '../user/user.model';
import * as auth from './auth.actions';

export interface ILoginPageState {
    loggedIn: boolean;
    user: IUser | null;
    error: boolean;
    pending: boolean;
}

export const initialState: ILoginPageState = {
    loggedIn: false,
    // loggedIn: !environment.production,
    user: null,
    error: false,
    pending: false
};

export function reducer(
    state = initialState,
    action: auth.Actions
): ILoginPageState {
    switch (action.type) {
        case auth.LOGIN: {
            return {
                ...state,
                error: null,
                pending: true
            };
        }

        case auth.LOGIN_SUCCESS: {
            return {
                ...state,
                loggedIn: true,
                user: action.payload.user,
                error: null,
                pending: false
            };
        }

        case auth.LOGIN_FAILURE: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }

        case auth.LOGOUT: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

export const selectAuthState = createFeatureSelector<ILoginPageState>('auth');

export const getLoggedIn = createSelector(
    selectAuthState,
    (state: ILoginPageState) => state.loggedIn
);
export const getUser = createSelector(
    selectAuthState,
    (state: ILoginPageState) => state.user
);

export const getLoginPageError = createSelector(
    selectAuthState,
    (state: ILoginPageState) => state.error
);
export const getLoginPagePending = createSelector(
    selectAuthState,
    (state: ILoginPageState) => state.pending
);
