import * as fromRouter from '@ngrx/router-store';
import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { IRouterStateUrl } from '../shared/utils';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
// import { storeFreeze } from 'ngrx-store-freeze';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import * as fromLayout from '../core/layout/layout.reducers';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface IState {
    layout: fromLayout.IState;
    routerReducer: fromRouter.RouterReducerState<IRouterStateUrl>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<IState> = {
    layout: fromLayout.reducer,
    routerReducer: fromRouter.routerReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<IState>): ActionReducer<IState> {
    return (state: IState, action: any): IState => {
        // tslint:disable-next-line:no-console
        console.log('state', state);
        // tslint:disable-next-line:no-console
        console.log('action', action);

        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<IState>[] = !environment.production
    ? [logger]
    : [];

/**
 * Layout Reducers
 */
export const getLayoutState = createFeatureSelector<fromLayout.IState>(
    'layout'
);

export const getShowSidenav = createSelector(
    getLayoutState,
    fromLayout.getShowSidenav
);
