import { Action } from '@ngrx/store';
import { IAuthenticate, IUser } from '../user/user.model';

export const LOGIN = '[API: Auth] Login';
export const LOGOUT = '[API: Auth] Logout';
export const LOGIN_SUCCESS = '[API: Auth] Login Success';
export const LOGIN_FAILURE = '[API: Auth] Login Failure';
export const LOGIN_REDIRECT = '[Auth] Login Redirect';

export class Login implements Action {
    public readonly type = LOGIN;

    constructor(public payload: IAuthenticate) {}
}

// tslint:disable:max-classes-per-file
export class LoginSuccess implements Action {
    public readonly type = LOGIN_SUCCESS;

    constructor(public payload: { user: IUser }) {}
}

export class LoginFailure implements Action {
    public readonly type = LOGIN_FAILURE;

    constructor(public payload: any) {}
}

export class LoginRedirect implements Action {
    public readonly type = LOGIN_REDIRECT;
}

export class Logout implements Action {
    public readonly type = LOGOUT;
}

export type Actions =
    | Login
    | LoginSuccess
    | LoginFailure
    | LoginRedirect
    | Logout;
