import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAuthenticate } from '../../user/user.model';
import * as Auth from '../auth.actions';
import * as fromAuth from '../auth.reducer';

@Component({
    selector: 'app-auth-container',
    templateUrl: './auth-container.component.html',
    styleUrls: ['./auth-container.component.css']
})
export class AuthContainerComponent {
    public pending$ = this.store.select(fromAuth.getLoginPagePending);
    public error$ = this.store.select(fromAuth.getLoginPageError);

    constructor(private store: Store<fromAuth.ILoginPageState>) {}

    public onSubmit($event: IAuthenticate) {
        this.store.dispatch(new Auth.Login($event));
    }
}
