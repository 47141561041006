import * as layout from './layout.actions';

export interface IState {
    showSidenav: boolean;
}

const initialState: IState = {
    showSidenav: true
};

export function reducer(state = initialState, action: layout.Actions): IState {
    switch (action.type) {
        case layout.CLOSE_SIDENAV:
            return {
                showSidenav: false
            };

        case layout.OPEN_SIDENAV:
            return {
                showSidenav: true
            };

        case layout.TOGGLE_SIDENAV:
            return {
                showSidenav: !state.showSidenav
            };

        default:
            return state;
    }
}

export const getShowSidenav = (state: IState) => state.showSidenav;
