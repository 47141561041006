import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAuthenticate } from '../../user/user.model';

@Component({
    selector: 'app-auth-form',
    templateUrl: './auth-form.component.html',
    styleUrls: ['./auth-form.component.css']
})
export class AuthFormComponent {
    @Input() public errorMessage: string | null;
    @Output() public submitted = new EventEmitter<IAuthenticate>();

    @Input()
    set pending(isPending: boolean) {
        this.isPending = isPending;
        if (isPending) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }
    get pending(): boolean {
        return this.isPending;
    }

    public form: FormGroup = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required)
    });

    private isPending = false;

    public submit() {
        if (this.form.valid) {
            this.submitted.emit(this.form.value);
        }
    }
}
