import { Action } from '@ngrx/store';

export const OPEN_SIDENAV = '[Layout] Open Sidenav';
export const CLOSE_SIDENAV = '[Layout] Close Sidenav';
export const TOGGLE_SIDENAV = '[Layout] Toggle Sidenav';

export class OpenSidenav implements Action {
    public readonly type = OPEN_SIDENAV;
}

export class CloseSidenav implements Action {
    public readonly type = CLOSE_SIDENAV;
}

export class ToggleSidenav implements Action {
    public readonly type = TOGGLE_SIDENAV;
}

export type Actions = OpenSidenav | CloseSidenav | ToggleSidenav;
