import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NotFoundPageComponent } from './core/not-found-page/not-found-page.component';

export const routes: Routes = [
    { path: '', redirectTo: '/orders', pathMatch: 'full' },
    {
        path: 'orders',
        loadChildren: './orders/order.module#OrderModule',
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsModule',
        canActivate: [AuthGuard]
    },
    { path: '**', component: NotFoundPageComponent }
];
